import React, { useState, useEffect } from "react";
import Joyride, { STATUS, ACTIONS, EVENTS } from "react-joyride"; // Import Joyride for the tutorial
import { useAuth } from "../../../contexts/AuthContext";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";

function Tutorial() {
  const { user, setUser } = useAuth();
  const [runTutorial, setRunTutorial] = useState(false); // State to control tutorial start
  const [stepIndex, setStepIndex] = useState(0); // Dynamic step index management

  // Effect to check if tutorial should run
  useEffect(() => {
    if (!user?.clientData?.tutorialComplete) {
      // Add a small delay before starting to ensure the layout is ready
      const timer = setTimeout(() => setRunTutorial(true), 700);
      return () => clearTimeout(timer); // Clear the timeout on unmount
    }
  }, [user]);

  // Function to update the user's `tutorialComplete` field in Firebase
  const markTutorialComplete = async () => {
    try {
      const userRef = doc(db, `clients/${user.clientId}`);
      await updateDoc(userRef, { tutorialComplete: true });
      setUser({
        ...user,
        clientData: {
          ...user.clientData,
          tutorialComplete: true,
        },
      });
    } catch (error) {
      console.error("Error updating tutorialComplete status:", error);
    }
  };

  // Handle tutorial completion (Joyride callback)
  const handleJoyrideCallback = (data) => {
    const { status, index, action, type } = data;

    // Check for back button action to decrement step index
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1); // Move to the previous step
    }

    // Check for next button action to increment step index
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1); // Move to the next step
    }

    // Handle tutorial completion
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTutorial(false); // Stop the tutorial
      markTutorialComplete(); // Mark tutorial as complete in Firebase
    }
  };

  // Define the steps for the tutorial
  const steps = [
 
    {
      target: ".print-checks-btn",
      content: "You can also print multiple checks in one go",
      disableBeacon: true,
      placement: "top",
    },

    {
      target: ".email-checks-btn",
      content: "Similarly, you can email single checks or multiple checks and save the post/stamp fees",
      disableBeacon: true,
      placement: "bottom", // Adjust the placement as needed
    },
    {
      target: ".alert-div",
      content:
        "In order to create new checks, you first have to add a bank account",
      disableBeacon: true,
      placement: "top", // Adjust placement to ensure it's visible
    },
  ];

  return (
    <>
      <style>{`
         button[data-action="close"] {
    display: none !important;
  }
      `}</style>

      <Joyride
        steps={steps}
        continuous
        stepIndex={stepIndex} // Manage steps dynamically
        run={runTutorial} // Start the tutorial when eligible
        callback={handleJoyrideCallback} // Handle Joyride callback for step transitions and completion
        showProgress
        disableCloseOnEsc // Prevent closing the tutorial with the Esc key
        disableOverlayClose // Prevent closing the tutorial by clicking on the overlay
        showSkipButton={false} // Explicitly disable the skip button
        spotlightPadding={10} // Optional, gives a bit of padding around highlighted elements
        locale={{
          back: "Back",
          next: "Next",
          last: "Finish",
        }}
      />
    </>
  );
}

export default Tutorial;
