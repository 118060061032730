import React, { useState, useEffect } from "react";
import { Table, Button, Space, Tooltip } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Row, Col, Alert } from "antd";

const FulfillmentOrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useAuth();

  const fetchFulfillmentOrders = async () => {
    try {
      const ordersCollection = query(
        collection(db, "fulfillmentOrders"),
        where("clientId", "==", user.clientId)
      );
      const ordersSnapshot = await getDocs(ordersCollection);
      const ordersList = ordersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        checksCount: doc.data().checks?.length || 0,
        documentsCount: doc.data().documents?.length || 0,
      }));
      setOrders(ordersList);
    } catch (error) {
      console.error("Error fetching fulfillment orders:", error);
    }
  };

  useEffect(() => {
    fetchFulfillmentOrders();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "dateAdded",
      key: "dateAdded",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Number of Checks",
      dataIndex: "checksCount",
      key: "checksCount",
    },
    {
      title: "Number of Documents",
      dataIndex: "documentsCount",
      key: "documentsCount",
    },
    {
      title: "Shipment Method",
      dataIndex: "shipmentMethod",
      key: "shipmentMethod",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space>
          <Tooltip title="Print Items">
            <Button type="primary">Print Items</Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1 className="monospace">
        <SendOutlined /> Fulfillment Orders
      </h1>
      <Card style={{ width: "100%" }}>
      <Row>
      <Col xs={24}>
        <Alert type="info" showIcon description="Fulfillment are orders where simple checks will print the check or document and mail on your behalf" />
        </Col>
      </Row>
        <Row gutter={16} style={{marginTop:15}}>
          <Col>
            <Button className="button-blue">New Fulfillment Order</Button>
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col xs={24}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={orders}
              pagination={{ pageSize: 10 }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FulfillmentOrdersTable;
