import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import {
  BankOutlined,
  UserOutlined,
  IdcardOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { Modal, Steps, Card, Typography, Spin, Row, Col } from "antd";
import OnboardingForm from "./NewUserJourney/OnboardingForm"; // Import the form component
import EmailSmsVerification from "./NewUserJourney/EmailSmsVerification";
import BankingVerification from "../Settings/BankAccountsTab/BankingVerification";
import Welcome from "./NewUserJourney/Welcome";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const { Title, Paragraph } = Typography;

const Onboarding = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const { urlParameter } = useParams();
  const [component, setComponent] = useState(null);

  useEffect(() => {
    if (user) {
      if (user.acceptTermsConditions && urlParameter && urlParameter === "sms" && (!user?.clientData?.verifications?.sms || !user?.clientData?.verifications?.email)) {
        setComponent(<EmailSmsVerification />);
        setCurrentStep("sms")
      } else if (!user.acceptTermsConditions) {
        setComponent(<OnboardingForm user={user} />);
        setCurrentStep("onboarding")
      } else{
        navigate(process.env.REACT_APP_STARTING_PAGE);
      }
    }
    //   setModalOpen(true);
    //   setCurrentStep(0);
    // } else if (
    //   user &&
    //   (!user.clientData?.verifications?.sms ||
    //     !user.clientData?.verifications?.email)
    // ) {
    //   setModalOpen(true);
    //   setCurrentStep(1);
    // } else if (user  && !user.clientData?.verifications?.bank) {
    //   setModalOpen(true);
    //   setCurrentStep(2);
    // } else if (
    //   user &&
    //   !user.clientData?.verifications?.preferences
    // ) {
    //   setModalOpen(true);
    //   setCurrentStep(3);
    // }else{
    //   setCurrentStep(3);
    //   setModalOpen(false);
    //   navigate('/')
    // }
  }, [user]);

  function nextStep() {
    setCurrentStep(currentStep + 1);
  }

  function previousStep() {
    setCurrentStep(currentStep - 1);
  }


  // const steps = [
  //   {
  //     title: "Onboarding",
  //     content: <OnboardingForm nextStep={nextStep} user={user} />,
  //     icon: <UserOutlined style={{ marginTop: 5 }} />,
  //   },
  //   {
  //     title: "Verification",
  //     content: (
  //       <EmailSmsVerification nextStep={nextStep} previousStep={previousStep} />
  //     ),
  //     icon: <IdcardOutlined style={{ marginTop: 5 }} />,
  //   },
  //   {
  //     title: "Bank Info",
  //     content: <BankingVerification nextStep={nextStep} previousStep={previousStep} />,
  //     icon: <BankOutlined style={{ marginTop: 5 }} />,
  //   },
  //   {
  //     title: "Welcome",
  //     icon: <CheckSquareOutlined style={{ marginTop: 5 }} />,
  //     content: <Welcome />,

  //   },
  // ];

  const handleClose = () => {
    navigate(-1); // This will navigate back to the previous page
    setModalOpen(false)
  };

  return (
    <>
      <Modal
      open={true}
      width="800"
      maskClosable={false}
      footer={null}
      closable={currentStep === "onboarding" ? false : true}
      onCancel={handleClose}
    >
        <Title>Getting Started</Title>
        <Paragraph>
          Let's gather some quick information to get you going:
        </Paragraph>
        <Card>
          {/* <Steps current={currentStep}>
            {steps.map((item, index) => (
              <Steps.Step key={index} title={item.title} icon={item.icon} />
            ))}
          </Steps> */}
          {/* <div style={{ textAlign: "center" }}>
            <Title level={2}>{steps[currentStep].title}</Title>
          </div> */}
          {/* <div style={{ marginTop: 50 }}>{steps[currentStep].content}</div> */}
          {component}
        </Card>
      </Modal>
    </>
  );
};

export default Onboarding;
