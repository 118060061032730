// src/components/Login.js
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Space,
  notification,
  Card,
  Divider,
} from "antd";
import { EditOutlined, GoogleOutlined, LoginOutlined } from "@ant-design/icons";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { lastLogin } from "../../services/lastLogin/lastLogin";

const { Title } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const LANDING_PAGE = process.env.REACT_APP_STARTING_PAGE;

  const { user, checkTokenAge } = useAuth;

  useEffect(() => {
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     // notification.success({ message: "User is already logged in" });
    //     // Redirect or other actions
    //     navigate("/");
    //   }
    // });
    if (user) {
      checkTokenAge();
    }
  }, [user]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = result.user;
      const uid = user.uid;

      // Refresh token to get latest custom claims
      await user.getIdToken(true);
      const idTokenResult = await user.getIdTokenResult();
      const clientId = idTokenResult.claims.clientId;

      // Record last login
      await lastLogin(clientId, uid);

      window.location.href = LANDING_PAGE;
    } catch (error) {
      notification.error({
        message: "Error signing in - password or email incorrect",
      });
    } finally {
      setLoading(false);
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const uid = user.uid;
      await user.getIdToken(true);
      const idTokenResult = await user.getIdTokenResult();
      const clientId = idTokenResult.claims.clientId;

      console.log(idTokenResult)

      await lastLogin(clientId, uid);

      window.location.href = LANDING_PAGE;
    } catch (error) {
      console.error("Login with Google Error:", error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          <img
            src="images/logo/logo.png"
            alt="logo"
            height="200px"
            width="200px"
          />
        </div>
        <Form name="login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "email required" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "password required" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginTop: "-20px" }}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px" }}>
            <Button htmlType="submit" type="primary" loading={loading} block>
              <LoginOutlined /> Login
            </Button>
          </Form.Item>
        </Form>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            icon={<GoogleOutlined />}
            onClick={loginWithGoogle}
            loading={loading}
            style={{
              width: "100%",
              // fontWeight: 'bold',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>Login with Google</span>
          </Button>
          <Divider style={{ margin: "10px 0" }} />
          <Link to="/register">
            <Button loading={loading} type="default" block>
              <EditOutlined /> Register
            </Button>
          </Link>
        </Space>
      </Card>
    </div>
  );
};

export default Login;
