import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid"; // Assuming you want to use UUID for generating new IDs

export async function metaAccountVerificationUpsert(user, account) {
  if (!account.verificationFile) {
    console.error("Missing verificationFile attribute");
    return;
  }

  // Use the provided account.id or generate a new one
  const accountId = account.id || uuidv4(); // Generate new UUID if no id is present

  try {
    const docRef = doc(db, "metaAccountVerification", accountId);

    // Upsert the document (create or update)
    await setDoc(
      docRef,
      {
        clientId: user.clientId,
        userId: user.uid,
        verificationFile: account.verificationFile,
        dateAdded: Date.now(),
      },
      { merge: true }
    );

    // console.log("Document successfully upserted with ID: ", accountId);
  } catch (e) {
    console.error("Error upserting verification file data: ", e);
  }
}
