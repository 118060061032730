import React from "react";
import { Card, Row, Col } from "antd";

const WordPressArticlesCard = ({ articles, width, height }) => {
  return (
    <Row
      gutter={[16, 16]}
      style={{
        display: "flex",
    
      }}
    >
      {articles.map((article, index) => (
        <Col xs={24} sm={12} md={8} lg={6} key={index}>
          <Card
            hoverable
            cover={
              <div style={{ overflow: 'hidden', height: '200px' }}>
                <img
                  alt={article.title}
                  src={article.image}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            }
            onClick={() => window.open(article.url, "_blank")}
            style={{ width, height }}
          >
            <Card.Meta style={{alignText: "center"}}
              title={article.title}
              // description={
              //   <div dangerouslySetInnerHTML={{ __html: article.summary }} />
              // }
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default WordPressArticlesCard;
