import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Col,
  Row,
  Alert,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
// import { defaultTemplate } from "../../../shared/checkTemplates/default";
import { addPayee } from "../../../shared/checkTemplates/demoData/demoPayee";
import { addCheck } from "../../../shared/checkTemplates/demoData/demoCheck";
import { updateProfile } from "firebase/auth";
import leadUpsertService from "../../../services/leadsServices";

const OnboardingForm = ({ nextStep, user }) => {
  const { Option } = Select;
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    displayName: user?.displayName || "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: user?.email || "",
  });

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const functions = getFunctions();
  const setCustomClaims = async (uid, clientId, clientName, role) => {
    const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");
    try {
      const result = await setCustomClaimsFunction({
        uid,
        clientId,
        clientName,
        role,
      });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (error) {
      console.error("Error setting custom claims:", error);
    }
  };

  const generateSecret = async (clientId) => {
    const generateSecretFunction = httpsCallable(functions, "generateSecret");
    try {
      const result = await generateSecretFunction({ clientId });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (e) {
      console.error("Error generating secret:", e.message);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.displayName.includes(" ")) {
      alert("Please enter your full name.");
      return;
    }
    setLoading(true);

    try {
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        ...values,
        isAdmin: true,
        role: "admin",
        isDeleted: false,
        clientId: "",
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      const clientRef = await addDoc(collection(db, "clients"), {
        clientName: values.companyName || values.displayName,
        hasAccess: true,
        disabled: false,
        subscription: "trial",
        trialExpiresOn: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        verifications: {
          email: false,
          sms: false,
          bank: false,
          preferences: false,
        },
        users: [
          {
            userId: user.uid,
            name: values.displayName,
            email: values.email,
            role: "admin",
          },
        ],
        userIds: [user.uid],
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      // await addDoc(collection(db, `clients/${clientRef.id}/templates`), {
      //   ...defaultTemplate,
      // });
      await updateDoc(userRef, { clientId: clientRef.id });

      await setCustomClaims(
        user.uid,
        clientRef.id,
        values.companyName || values.displayName,
        "admin"
      );

      await updateProfile(user, {
        name: values.displayName,
      });

      //async stuff
      await generateSecret(clientRef.id);
      //add demo records:
      const newPayeeId = await addPayee(clientRef.id);
      const newCheckId = await addCheck(user, clientRef.id, newPayeeId);

      //update marketing lead status
      try {
        leadUpsertService(user, {
          email: values.email,
          firstName: values.displayName.split(" ")[0],
          lastName: values.displayName.split(" ")[1],
          company: values.companyName,
          phone: values.phone,
          address1: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
          subscribed: true,
          addSegment: ["clients"],
          removeSegment: ["leads"],
        });
      } catch (error) {
        console.log(error.message);
      }

      if (nextStep) {
        nextStep();
      } else {
        window.location.href = process.env.REACT_APP_STARTING_PAGE;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating user data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
<Form
  name="user_form"
  initialValues={formData}
  onFinish={handleSubmit}
  onValuesChange={(changedValues) =>
    handleChange({
      target: {
        name: Object.keys(changedValues)[0],
        value: Object.values(changedValues)[0],
      },
    })
  }
>
  <Row gutter={[16, 16]}>
    <Col xs={24} md={12}>
      <Form.Item name="email">
        <Input placeholder="Email" disabled />
      </Form.Item>
    </Col>
    <Col xs={24} md={12}>
      <Form.Item
        name="displayName"
        rules={[{ required: true, message: "Full name is required" }]}
      >
        <Input placeholder="Full Name" />
      </Form.Item>
    </Col>
    <Col xs={24} md={12}>
      <Form.Item name="companyName">
        <Input placeholder="Company Name (Optional)" />
      </Form.Item>
    </Col>
    <Col xs={24} md={12}>
      <Form.Item
        name="address"
        rules={[{ required: true, message: "Please enter your address" }]}
      >
        <Input placeholder="Address" />
      </Form.Item>
    </Col>
    <Col xs={24} md={8}>
      <Form.Item
        name="city"
        rules={[{ required: true, message: "Please enter your city" }]}
      >
        <Input placeholder="City" />
      </Form.Item>
    </Col>
    <Col xs={24} md={8}>
      <Form.Item
        name="state"
        rules={[{ required: true, message: "Please enter your state" }]}
      >
        <Select placeholder="Select a state">
          {states.map((state) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    <Col xs={24} md={8}>
      <Form.Item
        name="zip"
        rules={[{ required: true, message: "Please enter your ZIP Code" }]}
      >
        <Input placeholder="ZIP Code" />
      </Form.Item>
    </Col>
    <Col xs={24} md={12}>
      <Form.Item
        name="phone"
        rules={[{ required: true, message: "Please enter your phone number" }]}
      >
        <Input placeholder="Phone Number" />
      </Form.Item>
    </Col>
    <Col xs={24}>
      <Form.Item
        name="acceptTermsConditions"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      "You must agree to terms and conditions to proceed"
                    )
                  ),
          },
        ]}
      >
        <Checkbox>
          I certify that I am authorized to print checks for this entity and I
          agree with{" "}
          <Link
            to="https://simple-checks.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </Link>{" "}
          and{" "}
          <Link
            to="https://simple-checks.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </Link>
          .
        </Checkbox>
      </Form.Item>
    </Col>
    <Col xs={24}>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          Save and Continue
        </Button>
        {loading && (
          <Alert
            style={{ marginTop: 15 }}
            type="info"
            message="Creating Account... this takes a few seconds."
          />
        )}
      </Form.Item>
    </Col>
  </Row>
</Form>
  );
};

export default OnboardingForm;
