import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";


export const addCheck = async (user, clientId, payeeId) => {
  try {
    const payeeData = {
      account: {
        accountNickname: "Demo Pay Account",
        id: 1,
      },
      amount: "150",
      checkNumber: "To Be Printed",
      comments: "This is a demo check, create yours now",
      createdBy: {
        id: user.uid,
        name: user.displayName,
      },
      date: new Date(),
      dateAdded: new Date(),
      dateModified: new Date(),
      isDeleted: false,
      mailingInfo:"John Doe\nDoe Corporation\n5000 Sunset Blvd Suite 100\nSummer Breeze, CA, 99911",
      memo: "Demo Check",
      payableTo: "John Doe",
      payee: {
        companyName: "Doe Corporation",
        id: payeeId,
        name: "John doe",
      },
      status: "to be printed",
      toBePrinted: true,
    };

    // Add a new document in the 'payee' collection
    const docRef = await addDoc(
      collection(db, `clients/${clientId}/checks`),
      payeeData
    );
    return docRef.id;
    // console.log("Document written with ID: ", docRef.id);
    // alert("Payee added successfully");
  } catch (error) {
    console.error("Error adding document: ", error);
    // alert("Failed to add payee");
  }
};


