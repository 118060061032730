export const getChangedFields = (newValues, originalValues) => {
    const changes = {};
  
    Object.keys(newValues).forEach((key) => {
      const newValue = newValues[key];
      const originalValue = originalValues[key];
  
      if (typeof newValue === 'object' && newValue !== null && !Array.isArray(newValue)) {
        // Recursively get changes for nested objects
        const nestedChanges = getChangedFields(newValue, originalValue || {});
        if (Object.keys(nestedChanges).length > 0) {
          changes[key] = nestedChanges;
        }
      } else if (newValue !== originalValue) {
        // Add field if it has changed
        changes[key] = newValue;
      }
    });
  
    return changes;
  };