import React, { useState, useEffect } from "react";
import { Tabs, Card } from "antd";
import Leads from "./Components/Leads";
import EmailTemplates from "./Components/EmailTemplates";
import Campaigns from "./Components/Campaigns";
import Segments from "./Components/Segments";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { RiseOutlined } from "@ant-design/icons";
import { useAuth} from "../../contexts/AuthContext";


const { TabPane } = Tabs;


const MarketingHub = () => {
  const [segments, setSegments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const { user } = useAuth();


  // Fetch segments from the /systemSettings/general document
  const fetchSegments = async () => {
    try {
      const systemSettingsRef = doc(db, "systemSettings", "marketingHub");
      const systemSettings = await getDoc(systemSettingsRef);
      if (systemSettings.exists()) {
        setSegments(systemSettings.data().segments || []);
      } else {
        console.error("No segments found.");
      }
    } catch (error) {
      console.error("Error fetching segments:", error);
    }
  };

  // Fetch templates from the marketingTemplates collection
  const fetchTemplates = async () => {
    try {
      const templatesCollectionRef = collection(db, "marketingTemplates");
      const templatesSnapshot = await getDocs(templatesCollectionRef);
      setTemplates(templatesSnapshot.docs.map((doc) => doc.data()));
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    fetchSegments();
    fetchTemplates();
  }, []);

  const tabItems = [
    {
      key: "1",
      label: "Campaigns",
      children: <Campaigns segments={segments} templates={templates} user={user} />,
    },
    {
      key: "2",
      label: "Email Templates",
      children: <EmailTemplates user={user} />,
    },
 
    {
      key: "3",
      label: "Segments",
      children: <Segments />,
    },
    {
      key: "4",
      label: "Leads",
      children: <Leads segments={segments} />,
    },
  ];

  return (
    <>
    <h1 className="monospace">
      <RiseOutlined /> Marketing Hub
    </h1>

    <Card style={{ overflowY: "scroll" }}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Card>
  </>
  );
};

export default MarketingHub;
