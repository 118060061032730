import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import WordPressArticlesCard from "../../Help/WordpressArticles";
import { Spin, Row, Button, Col } from "antd";
import ConfettiExplosion from "react-confetti-explosion";

function Welcome() {
  const [article, setArticle] = useState(null);
  const articleSlug = "print-your-first-check"; // Slug for the specific article
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Step 1: Fetch the article by slug
        const response = await axios.get(
          `https://simple-checks.com/wp-json/wp/v2/posts`,
          {
            params: {
              slug: articleSlug,
            },
          }
        );
        const articleData = response.data[0];

        // Step 2: Fetch the featured image for the article
        let imageUrl = "default_image_url.jpg";

        if (articleData.featured_media) {
          const mediaResponse = await axios.get(
            `https://simple-checks.com/wp-json/wp/v2/media/${articleData.featured_media}`
          );
          imageUrl = mediaResponse.data.source_url;
        }

        // Step 3: Set the article data
        setArticle({
          title: articleData.title.rendered,
          image: imageUrl,
          summary: articleData.excerpt.rendered,
          url: articleData.link,
        });
      } catch (error) {
        console.error("Error fetching the article or image:", error);
      }
    };

    fetchArticle();
  }, [articleSlug]);

  const handleClick = () => {
    navigate(process.env.REACT_APP_STARTING_PAGE || "/");
  };

  return (
    <>
      <Row>
        {article ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ConfettiExplosion zIndex={10000}  />
            <WordPressArticlesCard
              articles={[article]}
              width={400}
              style={{ marginRight: 300 }}
            />
          </div>
        ) : (
          <div
            style={{
              minHeight: 400,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Row>
      <Row>
        <Col xs={24} style={{ textAlign: "right", marginTop: 30 }}>
          <Button type="primary" onClick={handleClick}>
            Close
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Welcome;
