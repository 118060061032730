import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";


export function generateSecretKey(length = 64) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode(...array));
}

export async function decryptAccounts(accounts) {
  if (accounts && accounts.length > 0) {
    try {
      const decryptedValues = await Promise.all(
        accounts.map(async (account) => {
          const decryptFunction = httpsCallable(functions, "decryptData");
          const decryptedAccountInfo = await decryptFunction({
            encryptedText: account.encryptedAccount,
          });
          const { encryptedAccount, ...restOfAccount } = account;
          return {
            ...restOfAccount,
            account_info: JSON.parse(decryptedAccountInfo.data.plainText),
          };
        })
      );
      return decryptedValues;
    } catch (e) {
      console.error(e.message);
    }
  }
}

function generateKey(secretKey, salt) {
  return window.crypto.scryptSync(secretKey, salt, 32); // Derive a 256-bit key
}

export function encrypt(text, secretKey, salt) {
  const key = generateKey(secretKey, salt);
  const iv = window.crypto.randomBytes(16); // Initialization vector (128-bit)
  const cipher = window.crypto.createCipheriv("aes-256-cbc", key, iv);
  let encrypted = cipher.update(text, "utf8", "hex");
  encrypted += cipher.final("hex");
  return iv.toString("hex") + ":" + encrypted;
}

// Decrypt function
export function decrypt(encryptedText, secretKey, salt) {
  const key = generateKey(secretKey, salt);
  const parts = encryptedText.split(":");
  const iv = Buffer.from(parts.shift(), "hex");
  const encrypted = parts.join(":");
  const decipher = window.crypto.createDecipheriv("aes-256-cbc", key, iv);
  let decrypted = decipher.update(encrypted, "hex", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
}
