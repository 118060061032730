import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Select,
  message,
  Input,
  Table,
  Progress,
  Divider,
  Row,
  Col,
} from "antd";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  limit,
  getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { sendEmail } from "../../../utils/functions/mailer";
import axios from "axios";
import { formatDate } from "../../../utils/functions/dates";

const { Option } = Select;

const Campaigns = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [segments, setSegments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [recipientCount, setRecipientCount] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [viewCampaign, setViewCampaign] = useState(null);

  // Fetch segments from systemSettings/marketingHub
  const fetchSegments = async () => {
    try {
      const docRef = doc(db, "systemSettings", "marketingHub");
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setSegments(data.segments || []);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching segments:", error);
    }
  };

  // Fetch templates from marketingTemplates
  const fetchTemplates = async () => {
    const querySnapshot = await getDocs(collection(db, "marketingTemplates"));
    setTemplates(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  // Fetch campaigns from marketingCampaigns
  const fetchCampaigns = async () => {
    const querySnapshot = await getDocs(collection(db, "marketingCampaign"));
    setCampaigns(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  // Fetch leads based on selected segments
  const handleSegmentChange = (selectedSegments) => {
    // Find removed segments
    const removedSegments = selectedSegments.filter(
      (seg) => !selectedSegments.includes(seg)
    );

    // Filter out recipients that belong to the removed segments
    const updatedRecipients = recipients.filter(
      (recipient) =>
        !removedSegments.some((seg) => recipient.segment.includes(seg))
    );

    setRecipients(updatedRecipients);
    setRecipientCount(updatedRecipients.length);
    setSelectedSegments(selectedSegments);

    // Fetch new leads for the newly selected segments and ensure no duplicates
    fetchLeads(selectedSegments);
  };

  const fetchLeads = async (selectedSegments) => {
    if (selectedSegments.length > 0) {
      try {
        const leadsCollection = collection(db, "marketingLeads");

        // Count the total leads matching the selected segments
        const countQuery = query(
          leadsCollection,
          where("isDeleted", "==", false),
          where("subscribed", "==", true),
          where("segment", "array-contains-any", selectedSegments)
        );
        const countSnapshot = await getCountFromServer(countQuery);
        const totalLeadsCount = countSnapshot.data().count;
        setRecipientCount(totalLeadsCount); // Set total recipient count

        // Fetch a sample of 10 leads
        const sampleQuery = query(
          leadsCollection,
          where("isDeleted", "==", false),
          where("subscribed", "==", true),
          where("segment", "array-contains-any", selectedSegments),
        );
        const sampleSnapshot = await getDocs(sampleQuery);
        const sampleLeads = sampleSnapshot.docs.map((doc) => doc.data());

        setRecipients(sampleLeads); // Update recipients to show the sample
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    } else {
      setRecipients([]);
      setRecipientCount(0);
    }
  };

  // Handle template selection and show the subject and body preview
  const handleTemplateChange = (templateId) => {
    const selected = templates.find((template) => template.id === templateId);
    setSelectedTemplate(selected);
  };

  // Create campaign
  const createCampaign = async (values) => {
    const recipientsEmails = recipients.map((lead) => lead.email);
    const newCampaign = {
      ...values,
      recipients: recipientsEmails,
      recipientCount: recipientsEmails.length,
      sent: [],
      sentCount: 0,
      sentError: [],
      sentErrorCount: 0,
      bounced: [],
      bouncedCount: 0,
      unsubscribed: [],
      unsubscribedCount: 0,
      clicked: [],
      clickedCount: 0,
      opened: [],
      openedCount: 0,
      html: selectedTemplate.html,
      subject: selectedTemplate.subject,
      templateName: selectedTemplate.name,
      templateId: selectedTemplate.id,
      dateAdded: new Date(),
      dateBeginSend: null,
      dateCompleteSend: null,
      status: "draft",
      sentComplete: false,
    };

    await addDoc(collection(db, "marketingCampaign"), newCampaign);
    message.success(
      `Campaign created with ${recipientsEmails.length} recipients.`
    );
    setIsModalVisible(false);
    fetchCampaigns(); // Update list view
  };

  // Delete a draft campaign
  const deleteCampaign = async (id) => {
    await deleteDoc(doc(db, "marketingCampaign", id));
    message.success("Draft campaign deleted successfully.");
    fetchCampaigns(); // Update list view
  };

  // Send test email
  const sendTestEmail = async (campaign) => {
    const disclaimer = `<div style="font-size: 12px; color: #888; margin-top: 40px; text-align: center;">
    <p>
      This email was sent to ${
        user.email
      } in accordance with the CAN-SPAM Act and other applicable laws.
      If you no longer wish to receive these emails, you can <a href="{{unsubscribeDynamicLink}}" style="color: #888; text-decoration: underline;">unsubscribe here</a>.
    </p>
    <p>
      © ${new Date().getFullYear()} Simple Checks. All rights reserved.<br />
      123 Business St., Suite 456, Anytown, CA, 90210
    </p>
  </div>`;
    try {
      await sendEmail(
        user,
        user.email,
        campaign.subject,
        null,
        campaign.html + disclaimer
      );
      message.success(`Test email sent for campaign ${campaign.name}`);
    } catch (error) {
      message.error("Failed to send test email.");
    }
  };

  const calculatePercentage = (count, total) =>
    total > 0 ? ((count / total) * 100).toFixed(2) : 0;

  // Send campaign in batches of 50 recipients
  const sendCampaign = async (campaign) => {
    const url = process.env.REACT_APP_API_ENDPOINT;
    const recipients = campaign.recipients;
    let sentCount = 0;
    let success = true;
    setProgress(0.01)

    for (let i = 0; i < recipients.length; i += 50) {
      const batch = recipients.slice(i, i + 50);
      try {
        // API call to send batch of emails
        axios.post(
          `${url}/v1/marketing/massMail`,
          {
            emailArray: batch, // Current batch of recipients (max 50)
            subject: campaign.subject, // Campaign subject
            message: campaign.message, // Plain text message (if any)
            htmlMessage: campaign.html, // HTML message
            campaignId: campaign.id, // Campaign ID
            final: i + 50 >= recipients.length, // If it's the final batch
          },
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`, // Add Bearer token here
            },
          }
        );

        // Update sent count and progress
        sentCount += batch.length;
        setProgress(Math.round((sentCount / recipients.length) * 100));
        message.success(`Sent ${batch.length} emails in this batch.`);
      } catch (error) {
        // Handle errors
        success = false;
        message.error(`Failed to send batch of emails.`);
      }

      // 5-second delay between each batch
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    // After all batches are sent, update Firestore with the final campaign status
    if (success) {
      // await updateDoc(doc(db, "marketingCampaign", campaign.id), {
      //   sentCount: sentCount,
      //   sentComplete: true,
      //   dateCompleteSend: new Date(),
      // });

      // Final success message
      message.success("Campaign sent successfully!");
    } else {
      message.error("there was an issue sending");
    }

    // Reset progress and refresh campaign list
    setProgress(0);
    fetchCampaigns(); // Refresh list view
  };

  // Open view summary modal
  const openViewSummary = (campaign) => {
    setViewCampaign(campaign);
    setViewModalVisible(true);
  };

  useEffect(() => {
    fetchSegments();
    fetchTemplates();
    fetchCampaigns();
  }, []);

  return (
    <div>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Create Campaign
      </Button>
      {progress > 0 && <Progress percent={progress} />}

      <Modal
        title="Create Campaign"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
        okText="Create Campaign"
        width={900}
      >
        <Form form={form} onFinish={createCampaign} layout="vertical">
          <Form.Item
            label="Campaign Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          {/* Segment Multi-Select */}
          <Form.Item
            label="Select Segment"
            name="segment"
            rules={[{ required: true }]}
          >
            <Select mode="multiple" onChange={handleSegmentChange}>
              {segments.map((segment) => (
                <Option key={segment} value={segment}>
                  {segment}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* Template Select */}
          <Form.Item
            label="Select Template"
            name="template"
            rules={[{ required: true }]}
          >
            <Select onChange={handleTemplateChange}>
              {templates.map((template) => (
                <Option key={template.id} value={template.id}>
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* Recipient Multi-Select */}

          <>
            <Form.Item label="Recipients">
              <Select
                mode="multiple"
                value={recipients.map((lead) => lead.email)}
                disabled
              >
                {recipients.map((lead) => (
                  <Option key={lead.email} value={lead.email}>
                    {lead.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <p>Recipient count: {recipientCount}</p>
          </>

          {/* Template Subject */}
          {selectedTemplate && (
            <Form.Item label="Email Subject">
              <Input value={selectedTemplate.subject} disabled />
            </Form.Item>
          )}

          {/* Template Body Preview */}
          {selectedTemplate && (
            <div style={{ marginTop: "20px" }}>
              <iframe
                title="Email Preview"
                srcDoc={selectedTemplate.html}
                style={{
                  width: "100%",
                  height: "400px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          )}
        </Form>
      </Modal>

      <h2>Campaigns List</h2>
      <Table
        dataSource={campaigns}
        columns={[
          { title: "Name", dataIndex: "name", key: "name" },
          {
            title: "Recipients",
            dataIndex: "recipientCount",
            key: "recipientCount",
          },
          {
            title: "Opened",
            key: "opened",
            render: (_, campaign) => {
              const openedPercentage = calculatePercentage(
                campaign.openedCount,
                campaign.recipientCount
              );
              return `${campaign.openedCount} (${openedPercentage}%)`;
            },
          },
          {
            title: "Clicked",
            key: "clicked",
            render: (_, campaign) => {
              const clickedPercentage = calculatePercentage(
                campaign.clickedCount,
                campaign.recipientCount
              );
              return `${campaign.clickedCount} (${clickedPercentage}%)`;
            },
          },
          {
            title: "Unsubscribed",
            key: "unsubscribed",
            render: (_, campaign) => {
              const unsubscribedPercentage = calculatePercentage(
                campaign.unsubscribedCount,
                campaign.recipientCount
              );
              return `${campaign.unsubscribedCount} (${unsubscribedPercentage}%)`;
            },
          },
          {
            title: "Date Sent",
            dataIndex: "dateCompleteSend",
            key: "dateCompleteSend",
            render: (date) => (date ? formatDate(date) : "Not sent"),
          },
          {
            title: "Actions",
            render: (_, campaign) => (
              <div>
                {campaign.status === "draft" && (
                  <Button onClick={() => deleteCampaign(campaign.id)} danger>
                    Delete
                  </Button>
                )}
                <Button
                  onClick={() => sendTestEmail(campaign)}
                  style={{ marginLeft: 10 }}
                >
                  Email Me
                </Button>
                <Button
                  onClick={() => openViewSummary(campaign)}
                  style={{ marginLeft: 10 }}
                >
                  View
                </Button>
                {!campaign.sentComplete && (
                  <Button
                    onClick={() => sendCampaign(campaign)}
                    className="bg-warning-light warning"
                    style={{ marginLeft: 10 }}
                  >
                    Send Campaign
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />

      {/* View Summary Modal */}
      <Modal
        title={`Campaign Summary: ${viewCampaign?.name}`}
        open={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={null}
        width={800}
      >
        {viewCampaign && (
          <>
            <h3>Campaign Summary</h3>

            {/* Sent Count */}
            <Divider orientation="left">Sent Emails</Divider>
            <p>
              <strong>Sent:</strong> {viewCampaign.sentCount}
            </p>
            {viewCampaign.sent.length > 0 ? (
              <ul>
                {viewCampaign.sent.map((email, index) => (
                  <li key={`sent-${email}-${index}`}>{email}</li>
                ))}
              </ul>
            ) : (
              <p>No emails sent yet.</p>
            )}

            {/* Bounced Count */}
            <Divider orientation="left">Bounced Emails</Divider>
            <p>
              <strong>Bounced:</strong> {viewCampaign.bouncedCount}
            </p>
            {viewCampaign.bounced.length > 0 ? (
              <ul>
                {viewCampaign.bounced.map((email, index) => (
                  <li key={`bounced-${email}-${index}`}>{email}</li>
                ))}
              </ul>
            ) : (
              <p>No bounced emails.</p>
            )}

            {/* Unsubscribed Count */}
            <Divider orientation="left">Unsubscribed Emails</Divider>
            <p>
              <strong>Unsubscribed:</strong> {viewCampaign.unsubscribedCount}
            </p>
            {viewCampaign.unsubscribed.length > 0 ? (
              <ul>
                {viewCampaign.unsubscribed.map((email, index) => (
                  <li key={`unsubscribed-${email}-${index}`}>{email}</li>
                ))}
              </ul>
            ) : (
              <p>No unsubscribed emails.</p>
            )}

            {/* Clicked Count */}
            <Divider orientation="left">Clicked Emails</Divider>
            <p>
              <strong>Clicked:</strong> {viewCampaign.clickedCount}
            </p>
            {viewCampaign.clicked.length > 0 ? (
              <ul>
                {viewCampaign.clicked.map((email, index) => (
                  <li key={`clicked-${email}-${index}`}>{email}</li>
                ))}
              </ul>
            ) : (
              <p>No clicked emails.</p>
            )}

            {/* Opened Count */}
            <Divider orientation="left">Opened Emails</Divider>
            <p>
              <strong>Opened:</strong> {viewCampaign.openedCount}
            </p>
            {viewCampaign.opened.length > 0 ? (
              <ul>
                {viewCampaign.opened.map((email, index) => (
                  <li key={`opened-${email}-${index}`}>{email}</li>
                ))}
              </ul>
            ) : (
              <p>No opened emails yet.</p>
            )}

            {/* All Recipients */}
            <Divider orientation="left">All Recipients</Divider>
            <h4>Recipients List</h4>
            <ul>
              {viewCampaign.recipients.map((email, index) => (
                <li key={`recipient-${email}-${index}`}>{email}</li>
              ))}
            </ul>
          </>
        )}
      </Modal>

    </div>
  );
};

export default Campaigns;
