export const blankPaper = {
  title: "Any White Paper",
  templateId: "blank", //never change this
  templateImg: "blankPageTemplate.png",
  createdAt: new Date(),
  updatedAt: new Date(),
  enabled: true,
  isDeleted: false,
  options: {
    printDottedLine: true,
    printCheckNumber: true,
    printInstitutionName: true,
    printOwner: true,
    printRoutingAndAccount: true,
    printSignatureIfAvailable: true,
    printSignatureLine: true,
    printLabels: true,
    printInstructions: true,
    printBorderAroundCheck: true,
    printOfficialCheck: true,
    printAccountMask: true,
    printLogo: true
  },
  values: {
    instructionsText:
      "This is an official check. Print on any white paper. Cut check at dotted line, endorse in the back and deposit normally.",
    validateText:
      "This check is printed from authorized check software. You may scan the QR code to verify authenticity.",
    fraudText:
      "If you do not recognize this check do not print or deposit. Report fraud to simple-checks.com/contact ",
  },
  coordinates: {
    layout: {
      signatureLine: {
        y: 175,
        x: 395,
      },
      logo:{
        x:10,
        y:15
      },
      authorizedSignerLabel: {
        y: 177,
        x: 450,
      },
      amountLabel: {
        x: 10,
        y: 107,
      },
      mailToLabel: {
        x: 10,
        y: 137,
      },
      payToLabel: [
        {
          x: 10,
          y: 79,
        },
        { x: 120, y: 251 },
        { x: 120, y: 537 },
      ],
      toTheOrderOfLabel: [
        {
          x: 10,
          y: 88,
        },
        { x: 120, y: 260 },
        { x: 120, y: 546 },
      ],
      memoLabel: [
        {
          x: 10,
          y: 270,
        },
        {
          x: 10,
          y: 555,
        },
      ],
      dateLabel: [
        {
          x: 470,
          y: 50,
        },
        {
          x: 10,
          y: 253,
        },
        {
          x: 10,
          y: 540,
        },
      ],
      dollarSignLabel: {
        x: 400,
        y: 150,
      },
      payableToLine: {
        x: 400,
        y: 150,
      },
      writtenDollarLine: {},
      instructionsLabel: {
        x: -5,
        y: 485,
      },
      validateLabel: {
        x: -5,
        y: 495,
      },
      fraudLabel: {
        x: -5,
        y: 505,
      },
      officialCheckLabel: {
        x: 510,
        y: 200,
      },
    },
    instructions: {
      x: 58,
      y: 485,
    },
    validate: {
      x: 37,
      y: 495,
    },
    fraud: {
      x: 26,
      y: 505,
    },
    accountMask: [
      {
        x: 22,
        y: 465,
      },
      { x: 22, y: 740 },
    ],
    institutionName: {
      x: 350,
      y: 15,
    },
    ownerName: {
      x: 70,
      y: 15,
    },
    ownerAddressLine1: {
      x: 70,
      y: 28,
    },
    ownerAddressLine2: {
      x: 70,
      y: 40,
    },
    mailingInfo: {
      x: 70,
      y: 135,
    },
    micrRoutingAccount: {
      y: 215,
      x: 140,
    },
    signatureImg: {
      y: 150,
      x: 460,
    },
    writtenDollar: {
      y: 105,
      x: 70,
    },
    date: [
      {
        x: 22,
        y: 47,
      },
      {
        x: 485,
        y: 250,
      },
      {
        x: 485,
        y: 537,
      },
    ],
    payableTo: [
      {
        x: 70,
        y: 80,
      },
      {
        x: 180,
        y: 250,
      },
      {
        x: 180,
        y: 536,
      },
    ],
    memo: [
      {
        x: 40,
        y: 267,
      },
      {
        x: 40,
        y: 552,
      },
    ],
    amount: [
      {
        x: 22,
        y: 80,
      },
      {
        x: 22,
        y: 270,
      },
      {
        x: 22,
        y: 560,
      },
    ],
    checkNumber: [
      {
        x: 22,
        y: 15,
      },
      {
        x: 22,
        y: 250,
      },
      {
        x: 22,
        y: 540,
      },
    ],
  },
};
