import axios from "axios";

const leadUpsertService = async (user, values) => {
  if (!values.email) throw new Error("Email is required");
  try {
    const url = process.env.REACT_APP_API_ENDPOINT;
    const response = await axios.post(`${url}/v1/leads/upsert`, {...values}, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return;
  } catch (error) {
    console.error("Error upserting lead:", error);
    throw error.response?.data || { message: "Failed to upsert lead" };
  }
};

export default leadUpsertService;


// {
//     "email": "andrermusa@gmail.com",
//     "firstName": "",
//     "lastName": "",
//     "company": "",
//     "phone": "",
//     "address1": "",
//     "address2": "",
//     "city": "",
//     "state": "",
//     "zip": "33446",
//     "subscribed": true,
//     "lastEmailSent": "",
//     "addSegment": ["clients", "whatever"],
//     "removeSegment": ["leads"]
// }