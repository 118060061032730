import React, { useState } from "react";
import { Modal, Button, Upload, message, Slider, notification } from "antd";
import Cropper from "react-easy-crop";

function LogoUploadModal({ isOpen, onClose, account, onLogoSave }) {
  const [image, setImage] = useState(null); // Uploaded image
  const [croppedArea, setCroppedArea] = useState(null); // Cropped area details
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [imageType, setImageType] = useState(null); // Store image type

  // Reset the state of the modal when closing
  const resetState = () => {
    setImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedArea(null);
  };

  // Handle file upload
  const handleUpload = ({ file }) => {
    const isSupportedFileType =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml";
    if (!isSupportedFileType) {
      message.error("Only PNG, JPEG, and SVG files are allowed.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result); // Base64 image
      setImageType(file.type); // Save the type to handle SVG
    };

    reader.readAsDataURL(file); // For PNG/JPEG, read as base64
  };

  // Handle image crop complete
  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  // Utility function to crop and compress the image to keep it under 100kb

 const getCroppedImageAsBase64 = (imageSrc, croppedAreaPixels, type, setLoading) => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas size to the cropped area size
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        // Clear the canvas to preserve transparency
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the cropped image onto the canvas
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        // Compress the image and reduce quality to keep it below size limit
        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Data = reader.result;

              // Check the size of the base64 string
              if (base64Data.length > 1048487) {
                notification.error({
                  message: "Image too large",
                  description: "The image size exceeds the limit. Please use a smaller image.",
                });
                setLoading(false); // Stop loading
                reject(new Error("Image exceeds the size limit"));
                return;
              }

              resolve(base64Data);
            };

            // Read the compressed image blob as a base64 string
            reader.readAsDataURL(blob);
          },
          "image/png", // Use JPEG format for smaller file sizes (or PNG if transparency is needed)
          0.7 // Adjust the quality to reduce size (0.7 means 70% quality)
        );
      };

      image.onerror = () => {
        notification.error({
          message: "Image Load Error",
          description: "Failed to load the image. Please try again.",
        });
        setLoading(false);
        reject(new Error("Failed to load image"));
      };
    } catch (error) {
      setLoading(false); // Reset loading state
      notification.error({
        message: "Image Processing Error",
        description: error.message || "An error occurred while processing the image.",
      });
      reject(error);
    }
  });
};
  

  // Handle save button click
  const handleSave = async () => {
    if (!image) {
      return message.error("Please upload an image.");
    }

    setLoading(true);

    const base64Logo = await getCroppedImageAsBase64(
      image,
      croppedArea,
      imageType
    ); // Pass image type
    if (base64Logo) {
      await onLogoSave(account.id, base64Logo); // Save base64 logo instead of URL
    }
    setLoading(false);
    resetState();
    onClose();
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetState(); // Reset modal state
    onClose(); // Close the modal
  };

  return (
    <Modal
      title="Upload Logo"
      maskClosable={false}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      style={{ minHeight: "500px" }}
    >
      <div style={{ height: 400, position: "relative" }}>
        {image ? (
          <>
            <p>Center your logo below by dragging and zooming</p>
            <div
              style={{ position: "relative", height: "300px", marginTop: 30 }}
            >
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1} // 1:1 aspect ratio
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                  containerStyle: {
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  },
                }}
              />
            </div>

            {/* Zoom Slider Control */}
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <span>Zoom: </span>
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(value) => setZoom(value)}
                style={{ width: "80%", display: "inline-block" }}
              />
            </div>
          </>
        ) : (
          <Upload
            accept="image/png, image/jpeg, image/svg+xml"
            showUploadList={false}
            customRequest={({ file }) => handleUpload({ file })}
            style={{ textAlign: "center" }}
          >
            <Button>Select Image</Button>
          </Upload>
        )}
      </div>

      {/* Footer with Save and Cancel buttons */}
      <div style={{ marginTop: 20, textAlign: "right" }}>
        <Button onClick={handleCancel} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSave}
          loading={loading}
          disabled={!image}
        >
          Save Logo
        </Button>
      </div>
    </Modal>
  );
}

export default LogoUploadModal;
