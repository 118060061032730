import React, { useState } from "react";
import { db } from "../../../../firebase";
import { Modal, Form, Input, Checkbox, Button, Row, Col } from "antd";
import { collection, addDoc } from "firebase/firestore";

export const GenerateAPIKeyModal = ({ clientId, onClose }) => {
  const [permissions, setPermissions] = useState([]);

  const handleGenerate = async (values) => {
    const apiKey = generateApiKey(); // Generate unique API key
    const apiSecret = generateApiSecret(); // Generate secure secret
    await addDoc(collection(db, `/clients/${clientId}/apiKeys`), {
      ...values,
      apiKey,
      apiSecret,
      permissions,
    });
    onClose();
  };

  const permissionsList = [
    "read_check",
    "modify_check",
    "email_check",
    "send_fulfillment",
    "read_payee",
    "modify_payee",
    "read_documents",
    "modify_documents",
    "send_documents_fulfillment",
  ];

  return (
    <Modal
      title="Generate New API Key"
      visible
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleGenerate}>
        <Form.Item
          name="name"
          label="API Key Name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input placeholder="Enter API Key Name" />
        </Form.Item>
        <Form.Item label="Permissions">
          <Checkbox.Group
            options={permissionsList.map((perm) => ({
              label: perm.replace("_", " ").toUpperCase(),
              value: perm,
            }))}
            onChange={setPermissions}
          />
        </Form.Item>
        <Row justify="end" gutter={8}>
          <Col>
            <Button onClick={onClose}>Cancel</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Generate
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const generateApiKey = () => Math.random().toString(36).substr(2, 10); // Sample generator
const generateApiSecret = () => Math.random().toString(36).substr(2, 20);
