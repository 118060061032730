import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Table,
  Checkbox,
  notification,
  Form,
  Row,
  Col,
  Select,
  Button,
} from "antd";
import { formatDate } from "../../../utils/functions/dates";
import { qbTemplate } from "../../../shared/checkTemplates/qbTemplate";
import { checkStock } from "../../../shared/checkTemplates/checkStockTemplate";
import { blankPaper } from "../../../shared/checkTemplates/blankPaperTemplate";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PrintPreviewModal from "./PrintPreview";
import { decryptAccounts } from "../../../utils/functions/encryption";

const PrintChecksModal = ({
  isVisible,
  onOk,
  onCancel,
  accounts,
  selectedChecksToPrint,
  setSelectedChecksToPrint,
  startingCheckNumber,
  setStartingCheckNumber,
  template,
  isPDFModalVisible,
  handlePrintConfirm,
}) => {
  const [templates, setTemplates] = useState([]);
  const [form] = Form.useForm();
  const [decAccounts, setDecAccounts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    let templateValues = [];
    if (template && template.length > 0) {
      templateValues.push(...template);
    }
    templateValues.push(blankPaper, checkStock, qbTemplate);
    setTemplates(templateValues);

    const handleDecryption = async () => {
      if (accounts && accounts.length > 0) {
        const results = await decryptAccounts(accounts);
        setDecAccounts(results);
      }
    };

    handleDecryption();
  }, [accounts]);

  useEffect(() => {
    form.setFieldValue("startingCheckNumber", startingCheckNumber);
  }, [startingCheckNumber]);

  // Define columns for desktop and mobile
  const columns = [
    {
      title: "Select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedChecksToPrint.includes(record)}
          onChange={() => {
            let selected = [...selectedChecksToPrint];
            if (selected.includes(record)) {
              setSelectedChecksToPrint(
                selected.filter((item) => item.id !== record.id)
              );
            } else {
              selected.push(record);
              selected = selected.filter((item) => !item.isDeleted);
              setSelectedChecksToPrint(selected);
            }
          }}
        />
      ),
    },
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
      responsive: ["md"],
    },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
      responsive: ["md"],
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
      responsive: ["md"],
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  return (
    <>
      <Modal
        title="Select Checks to Print"
        open={isVisible}
        okText="Generate Checks"
        onOk={() => {
          if (!startingCheckNumber) {
            notification.error({ message: "Enter starting check number" });
            return;
          }

          const selectedTemplate = form.getFieldValue("template");
          if (selectedTemplate === undefined || selectedTemplate === null) {
            notification.warning({ message: "Please select a check template" });
            return;
          }

          onOk();
        }}
        onCancel={onCancel}
        style={{
          minWidth: "90%",
          marginTop: isMobile ? 10 : -70,
          minHeight: "80vh",
          minHeight: "100vh",

          overflowY: "auto",
          marginTop: "-70",
        }}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          form={form}
          style={{
            backgroundColor: "#ecf0f1",
            padding: 10,
            borderRadius: 10,
            marginBottom: 15,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={6}>
              <Form.Item
                label="Starting Check Number"
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
                name="startingCheckNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter the starting check number",
                  },
                ]}
              >
                <Input
                  value={startingCheckNumber}
                  onChange={(e) => setStartingCheckNumber(e.target.value)}
                  placeholder="Starting check number"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <Form.Item
                name="template"
                label="Check Print Template"
                rules={[
                  { required: true, message: "Please select check template" },
                ]}
              >
                <Select>
                  {templates?.map((template, index) => (
                    <Select.Option key={template.title} value={index}>
                      {template.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={3}>
              <Button
                type="primary"
                style={{ width: "100%", marginTop: 30 }}
                onClick={() =>
                  window.open(
                    "https://simple-checks.com/choosing-the-right-check-template/",
                    "_blank"
                  )
                }
              >
                <QuestionCircleOutlined /> Help Me Choose
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={selectedChecksToPrint}
          pagination={false}
          scroll={{ x: true }}
        />
      </Modal>
      {isPDFModalVisible === true && (
        <PrintPreviewModal
          isPDFModalVisible={isPDFModalVisible}
          handlePrintConfirm={handlePrintConfirm}
          selectedChecksToPrint={selectedChecksToPrint}
          templates={templates[form.getFieldValue("template")]}
          accounts={decAccounts}
          startingCheckNumber={startingCheckNumber}
          formatDate={formatDate}
        />
      )}
    </>
  );
};

export default PrintChecksModal;
