// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDOX5Uu_WvQ2yMyC7e9XjtXx3c0lerqeRg",
  authDomain: "simplechecks-prod.firebaseapp.com",
  databaseURL: "https://simplechecks-prod-default-rtdb.firebaseio.com",
  projectId: "simplechecks-prod",
  storageBucket: "simplechecks-prod.appspot.com",
  messagingSenderId: "637909922380",
  appId: "1:637909922380:web:16c9900671fd02f6a87ae9",
  measurementId: "G-D4C7RG3BBH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firebase Auth
const functions = getFunctions(app);

// Initialize Firebase Auth
const storage = getStorage(app);

//initialize Remote Config
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000, // 1 hour (adjust as needed)
};

//Initialize Analytics
const analytics = getAnalytics(app);

export { db, auth, analytics, remoteConfig, functions, storage };
