import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Tag, message, Row, Col } from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import axios from "axios";
import InviteUser from "./InviteUser";
import LoadingSpinner from "../../../shared/Components/LoadingSpinner";

const UserList = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const api = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${api}/v1/users`,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );
        setUsers(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        message.error("Failed to fetch users 1");
      }
    };
    if (user && api) {
      fetchUsers();
    }
  }, [api, user]);

  const handleDisable = async (userId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${api}/v1/users/${userId}/disable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      message.success("User disabled successfully");
      setUsers(
        users.map((u) => (u.id === userId ? { ...u, isDisabled: true } : u))
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      message.error("Failed to disable user");
    }
  };

  const handleEnable = async (userId) => {
    setIsLoading(true);
    try {
      await axios.post(`${api}/v1/users/${userId}/enable`, {}, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      message.success("User enabled successfully");
      setUsers(
        users.map((u) => (u.id === userId ? { ...u, isDisabled: false } : u))
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      message.error("Failed to enable user");
    }
  };

  const handleDelete = async (userId) => {
    setIsLoading(true);
    try {
      await axios.delete(`${api}/v1/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      message.success("User deleted successfully");
      setUsers(users.filter((u) => u.id !== userId));
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      console.log(error.message);
      message.error("Failed to delete user");
    }
  };

  const columns = [
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (isAdmin) =>
        isAdmin ? <Tag color="red">Admin</Tag> : <Tag color="blue">User</Tag>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Row gutter={16}>
          {record.isDisabled ? (
            <Col>
              {" "}
              <Button
                onClick={() => handleEnable(record.id)}
                disabled={record.isAdmin ? true : false}
              >
                Enable
              </Button>
            </Col>
          ) : (
            <Col>
              <Button
                onClick={() => handleDisable(record.id)}
                disabled={record.isAdmin ? true : false}
              >
                Disable
              </Button>
            </Col>
          )}
          <Col>
            <Button
              onClick={() => handleDelete(record.id)}
              danger
              disabled={record.isAdmin ? true : false}
            >
              Delete
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Table dataSource={users} columns={columns} rowKey="id" />
      <InviteUser />
    </>
  );
};

export default UserList;
