import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Tooltip,
  Space,
  Badge,
  Popconfirm,
  Tag,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  MailOutlined,
  CloseSquareOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/functions/dates";
import PrintPreviewModal from "./PrintPreview";
import { decryptAccounts } from "../../../utils/functions/encryption";
import axios from "axios";

const CheckTable = ({
  checks,
  showModal,
  handleDelete,
  setSelectedChecksToPrint,
  handlePrintConfirm,
  setLastCheckNumber,
  isPDFModalVisible,
  selectedChecksToPrint,
  setIsPdfModalVisible,
  accounts,
  templates,
  user,
  handleVoid,
  setECheckModalOpen,
  eCheckModalOpen,
  handleSendEmail,
  startingCheckNumber,
  openECheckModal,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Control loading state
  const [checkNumber, setCheckNumber] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [defaultAccount, setDefaultAccount] = useState();
  const [decAccounts, setDecAccounts] = useState([]);
  const hasDecrypted = useRef(false); // <-- Flag to ensure decryption runs only once

  useEffect(() => {
    if (user) {
      // Set default template if it exists in user preferences
      if (user?.clientData?.preferences?.defaultTemplate) {
        const matchingTemplate = templates.find(
          (template) =>
            template.id === user.clientData.preferences.defaultTemplate
        );
        setDefaultTemplate(matchingTemplate || null);
      }

      // Set default account if it exists in user preferences
      if (user?.clientData?.preferences?.defaultAccount) {
        const matchingAccount = accounts.find(
          (account) => account.id === user.clientData.preferences.defaultAccount
        );
        setDefaultAccount(matchingAccount || null);
      }

      // Decrypt accounts only once when accounts are first available
      const handleDecryption = async () => {
        if (!hasDecrypted.current && accounts && accounts.length > 0) {
          setLoading(true);
          try {
            const result = await decryptAccounts(accounts);
            setDecAccounts(result);
            hasDecrypted.current = true; // <-- Set flag to true after decryption
          } catch (error) {
            console.error("Error decrypting accounts:", error);
          } finally {
            setLoading(false);
          }
        }
      };

      if (!decAccounts) handleDecryption(); // Call once when accounts are ready
    }
  }, [checks, user, templates, accounts]); // Kept `accounts` to ensure decryption when accounts are available

  async function handleQuickPrint(check) {
    setSelectedChecksToPrint([check]);
    setLoading(false);
    setSelectedChecksToPrint([check]);
    setIsPdfModalVisible(true);
  }

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space className="quick-action-div">
          {record.status.toLowerCase() !== "void" && (
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                type="dashed"
                shape="circle"
                onClick={() => showModal(record)}
              ></Button>
            </Tooltip>
          )}
          {record.toBePrinted && (
            <Tooltip title="Quick Print">
              <Button
                icon={<PrinterOutlined className="info" />}
                type="dashed"
                shape="circle"
                className="quick-print-btn" // Add this class for the tutorial step
                onClick={() => handleQuickPrint(record)}
              ></Button>
            </Tooltip>
          )}
          {(record.toBePrinted || record.status.toLowerCase() === "draft") && (
            <Tooltip title="Email Check">
              <Button
                icon={<MailOutlined className="warning" />}
                type="dashed"
                shape="circle"
                onClick={() => openECheckModal(record)}
              ></Button>
            </Tooltip>
          )}
          {((record.status !== "printed" && record.status !== "emailed") ||
            record.status === "void") &&
            (user.role !== "admin" || user.role !== "employee") && (
              <Popconfirm
                title="Are you sure you want to delete this check?"
                onConfirm={() => handleDelete(record.id)}
              >
                <Tooltip title="Delete" color="darkRed">
                  <Button
                    icon={<DeleteOutlined className="error" />}
                    type="dashed"
                    shape="circle"
                    disabled={
                      record.status === "printed" &&
                      user.role !== "admin" &&
                      user.role !== "employee"
                    }
                  ></Button>
                </Tooltip>
              </Popconfirm>
            )}
          {record.status.toLowerCase() !== "to be printed" &&
            record.status.toLowerCase() !== "draft" &&
            record.status.toLowerCase() !== "void" &&
            (user.role !== "admin" || user.role !== "employee") && (
              <Popconfirm
                title="Are you sure you want to void the check? If the check was emailed, the recipient will no longer be able to print it after voided."
                onConfirm={() => handleVoid(record.id)}
              >
                <Tooltip title="Void" color="darkOrange">
                  <Button
                    icon={<CloseSquareOutlined className="orange" />}
                    type="dashed"
                    shape="circle"
                    disabled={
                      record.status === "printed" &&
                      user.role !== "admin" &&
                      user.role !== "employee"
                    }
                  ></Button>
                </Tooltip>
              </Popconfirm>
            )}
        </Space>
      ),
    },
   
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
      sorter: (a, b) => {
        const numA = parseInt(a.checkNumber, 10);
        const numB = parseInt(b.checkNumber, 10);

        // If both are valid numbers, sort numerically
        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }

        // If one or both are not numbers, sort as strings
        return String(a.checkNumber).localeCompare(String(b.checkNumber));
      },
    },

    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
   
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        let classValue;
        switch (status) {
          case "draft":
            classValue = "bg-gray-light gray";
            break;
          case "to be printed":
            classValue = "bg-info-light info";
            break;
          case "printed":
            classValue = "bg-success-light success";
            break;
          case "void":
            classValue = "bg-error-light error";
            break;
          case "emailed":
            classValue = "bg-success-light success";
            break;
          default:
            classValue = "bg-gray-light gray";
            break;
        }

        return (
          <>
            {/* Status tag */}
            <Tag className={classValue}>
              {status.toLowerCase() === "to be printed"
                ? "To Be Printed"
                : status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>

            {/* Render tags for each substatus */}
            {record.substatus &&
              record.substatus.map((substatus, index) => {
                if (substatus === "opened") {
                  return (
                    <Tooltip key={index} title="Email Opened by Recipient">
                      <Tag className="bg-info-light info">
                        <EyeOutlined style={{paddingTop:"4",paddingBottom:"4"}}/>
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "downloaded") {
                  return (
                    <Tooltip key={index} title="Recipient Downloaded the Check" style={{padding:0, margin:0}}>
                      <Tag className="bg-info-light info">
                        <CloudDownloadOutlined style={{paddingTop:"4",paddingBottom:"4"}} />
                      </Tag> 
                    </Tooltip>
                  );
                }
              })}
          </>
        );
      },
    },
   
    {
      title: "Check Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Account Nickname",
      key: "accountNickname",
      render: (text, record) => record?.account?.accountNickname,
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={checks}
        pagination={{ pageSize: 10 }}
        style={{marginTop:10}}
      />
      {!loading && isPDFModalVisible && (
        <PrintPreviewModal
          isPDFModalVisible={isPDFModalVisible}
          setPdfModalVisible={setIsPdfModalVisible}
          handlePrintConfirm={handlePrintConfirm}
          selectedChecksToPrint={selectedChecksToPrint}
          template={templates}
          accounts={decAccounts}
          startingCheckNumber={checkNumber}
          formatDate={formatDate}
        />
      )}
    </>
  );
};

export default CheckTable;
