import React, { useState, useRef } from "react";
import { Modal, Row, Col, Typography, Button, Input, Checkbox, message, Spin, Radio } from "antd";
import { SignaturePad } from "../../../shared/Components/Signature"; // Import SignaturePad

const SignatureModal = ({ isOpen, onClose, selectedAccount, onSave }) => {
  const [signatureOption, setSignatureOption] = useState("adopt"); // Options: 'draw' or 'adopt'
  const [typedSignature, setTypedSignature] = useState("");
  const [signatureStyle, setSignatureStyle] = useState("signature-style-one"); // Default signature style
  const [disclosureAccepted, setDisclosureAccepted] = useState(false);
  const [signatureImg, setSignatureImg] = useState(null); // Store signature image
  const [loading, setLoading] = useState(false); // Add loading state
  const signaturePadRef = useRef(null); // Reference for the SignaturePad

  // Function to reset the form state
  const resetState = () => {
    setTypedSignature("");
    setSignatureStyle("signature-style-one");
    setDisclosureAccepted(false);
    setSignatureImg(null);
    setSignatureOption("adopt");
    if (signaturePadRef.current) {
      signaturePadRef.current.clear(); // Clear the drawn signature canvas
    }
  };

  const handleAddSignature = async () => {
    if (!disclosureAccepted) {
      return message.error("You must accept the legal disclosure.");
    }

    if (
      (signatureOption === "adopt" && typedSignature) ||
      (signatureOption === "draw" && signatureImg)
    ) {
      setLoading(true); // Set loading to true before processing

      try {
        if (signatureOption === "adopt" && typedSignature) {
          const cursiveSignature = typedSignature; 
          setSignatureImg(cursiveSignature);
          await onSave(selectedAccount, signatureOption, cursiveSignature, signatureStyle); 
        } else if (signatureOption === "draw" && signatureImg) {
          await onSave(selectedAccount, signatureOption, signatureImg, signatureStyle);
        }
        resetState(); // Reset the state after saving
        onClose(); // Close the modal
      } catch (error) {
        message.error("Failed to save the signature.");
      } finally {
        setLoading(false); // Reset loading after processing
      }
    } else {
      message.error("No signature provided.");
    }
  };

  // Handle cancel by resetting the state and closing the modal
  const handleCancel = () => {
    resetState(); // Reset the form when cancelling
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleAddSignature}
      onCancel={handleCancel} // Handle cancel
      maskClosable={false}
      okText="Save Signature"
      title="Add Signature"
      okButtonProps={{ disabled: loading }} // Disable save button while loading
      cancelButtonProps={{ disabled: loading }} // Disable cancel button while loading
    >
      <Spin spinning={loading}> {/* Wrap content in Spin */}
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>Choose Signature Option</Typography.Title>
            <Button
              type={signatureOption === "adopt" ? "primary" : "default"}
              onClick={() => setSignatureOption("adopt")}
              disabled={loading} // Disable button while loading
            >
              Adopt Digital Signature
            </Button>
            <Button
              type={signatureOption === "draw" ? "primary" : "default"}
              onClick={() => setSignatureOption("draw")}
              style={{ marginLeft: 10 }}
              disabled={loading} // Disable button while loading
            >
              Draw Signature
            </Button>
          </Col>

          {signatureOption === "adopt" && (
            <Col span={24} style={{ marginTop: 20 }}>
              <Input
                placeholder="Type your name"
                value={typedSignature}
                onChange={(e) => setTypedSignature(e.target.value)}
                style={{ marginBottom: 20 }}
                disabled={loading} // Disable input while loading
              />
              
              {/* Radio buttons for signature style selection */}
              <Radio.Group
                onChange={(e) => setSignatureStyle(e.target.value)}
                value={signatureStyle}
                disabled={loading} // Disable radio group while loading
              >
                <Radio value="signature-style-one">Style One</Radio>
                <Radio value="signature-style-two">Style Two</Radio>
              </Radio.Group>

              {/* Signature rendering box */}
              <div style={{ textAlign: "center", borderRadius: 5, marginTop: 20 }} className="bg-gray-light">
                <Typography.Paragraph
                  className={signatureStyle}
                  style={{ fontSize: 60, marginBottom: 10, minHeight: 100 }} // Ensures it's always rendered
                >
                  {typedSignature || " "} {/* Render empty space if no signature */}
                </Typography.Paragraph>
              </div>
            </Col>
          )}

          {signatureOption === "draw" && (
            <Col span={24}>
              <SignaturePad setSignatureImg={setSignatureImg} ref={signaturePadRef} /> {/* Pass ref to SignaturePad */}
            </Col>
          )}

          <Col span={24} style={{ marginTop: -10 }}>
            <Typography.Title level={5}>Legal Disclosure</Typography.Title>
            {/* Replace TextArea with a div that allows links */}
            <div
              style={{
                width: "96%",
                maxHeight: 150,
                overflowY: "scroll",
                padding: "10px",
                border: "1px solid #d9d9d9",
                borderRadius: 5,
                backgroundColor: "#f7f7f7",
              }}
            >
              By signing this document, you acknowledge that you are accepting the terms and conditions outlined by US law regarding the use of electronic signatures. This signature is legally binding in the same way as a handwritten signature, as permitted by the <a href="https://www.congress.gov/bill/106th-congress/house-bill/1714" target="_blank" rel="noopener noreferrer">ESIGN Act</a>.
            </div>
          </Col>

          <Col span={24} style={{ marginTop: 20 }}>
            <Checkbox
              checked={disclosureAccepted}
              onChange={(e) => setDisclosureAccepted(e.target.checked)}
              disabled={loading} // Disable checkbox while loading
            >
              I accept the legal disclosure regarding digital signatures in the US.
            </Checkbox>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default SignatureModal;
