import React, { useState, useEffect } from 'react';
import { Input, Button, Tag, message } from 'antd';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../../firebase';

const Segments = () => {
  const [segments, setSegments] = useState([]);
  const [newSegment, setNewSegment] = useState('');

  // Firestore document reference to `/systemSettings`
  const docRef = doc(db, 'systemSettings', 'marketingHub'); // Firestore document path

  // Fetch segments from `/systemSettings` under the `marketingHub` object
  const fetchSegments = async () => {
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setSegments(data.segments || []); // Access the segments array inside marketingHub
      } else {
        message.error("No segments found.");
      }
    } catch (error) {
      console.error("Error fetching segments:", error);
      message.error("Error fetching segments.");
    }
  };

  useEffect(() => {
    fetchSegments();
  }, []);

  // Add a new segment
  const addSegment = async () => {
    if (newSegment && !segments.includes(newSegment)) {
      try {
        await updateDoc(docRef, {
          segments: arrayUnion(newSegment) // Use arrayUnion to push a new segment
        });
        setSegments((prevSegments) => [...prevSegments, newSegment]);
        setNewSegment('');
        message.success('Segment added successfully.');
      } catch (error) {
        console.error("Error adding segment:", error);
        message.error("Error adding segment.");
      }
    } else {
      message.error('Segment already exists or is empty.');
    }
  };

  // Remove a segment
  const removeSegment = async (segment) => {
    try {
      await updateDoc(docRef, {
        segments: arrayRemove(segment) // Use arrayRemove to pop a segment
      });
      setSegments((prevSegments) => prevSegments.filter(s => s !== segment));
      message.success('Segment removed successfully.');
    } catch (error) {
      console.error("Error removing segment:", error);
      message.error("Error removing segment.");
    }
  };

  return (
    <div>
      <Input
        value={newSegment}
        onChange={(e) => setNewSegment(e.target.value)}
        placeholder="Add Segment"
        style={{ width: '60%', marginRight: 8 }}
      />
      <Button onClick={addSegment} type="primary">Add Segment</Button>

      <div style={{ marginTop: 16 }}>
        {segments.map((segment) => (
          <Tag
            key={segment}
            color="blue"
            closable
            onClose={() => removeSegment(segment)} // Allow removal on close
            style={{ marginBottom: 8 }}
          >
            {segment}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default Segments;
