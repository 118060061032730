import moment from "moment";

export function formatDate(date) {
  if(!date) return null;
  
  try {
    return moment(date.toDate()).format("MM/DD/YYYY");
  } catch (e) {
    return null;
  }
}
