import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Tag,
  Spin,
  Form,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { formatDate } from "../../../utils/functions/dates";
import { Link } from "react-router-dom";
import { decryptAccounts } from "../../../utils/functions/encryption";

const SendECheckModal = ({
  setECheckModalOpen,
  eCheckModalOpen,
  handleSendEmail,
  selectedChecksToPrint,
  emailInputs,
  setEmailInputs,
  accounts,
  startingCheckNumber,
  setStartingCheckNumber,
}) => {
  const [form] = Form.useForm();
  const [loadingStates, setLoadingStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [decAccounts, setDecAccounts] = useState([]);
  const [localEmailInputs, setLocalEmailInputs] = useState(emailInputs);

  const handleEmailInputChange = (checkId, email) => {
    setLocalEmailInputs((prevInputs) => ({
      ...prevInputs,
      [checkId]: email,
    }));
  };

  const handleEmailInputBlur = (checkId) => {
    setEmailInputs((prevInputs) => ({
      ...prevInputs,
      [checkId]: localEmailInputs[checkId],
    }));
  };

  const sendEmail = async (record, email) => {
    setLoadingStates((prev) => ({ ...prev, [record.id]: true }));
    try {
      await handleSendEmail(record, email);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [record.id]: false }));
    }
  };

  useEffect(() => {
    const decryptAccountsData = async () => {
      if (accounts && accounts.length > 0 && decAccounts.length === 0) {
        try {
          const result = await decryptAccounts(accounts);
          setDecAccounts(result);
        } catch (error) {
          console.error("Error decrypting accounts:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    form.setFieldsValue({ startingCheckNumber });
    decryptAccountsData();
  }, [accounts, decAccounts.length]);

  const columns = [
    {
      title: "Email Check To",
      key: "emailCheckTo",
      render: (text, record) => {
        const account = decAccounts.find((acc) => acc.id === record.account.id);
        const isEmailSent = emailInputs[record.id] === "sent";
        const isError = emailInputs[record.id] === "error";
        const isLoading = loadingStates[record.id];

        if (loading) return <Spin />;

        if (!account || !account.account_info.signature) {
          return (
            <span className="error">
              Account <strong>{account?.accountNickname || "N/A"}</strong> lacks a signature.{" "}
              <Link to={`${process.env.REACT_APP_BASE_URL}/settings/bank-accounts`}>
                <strong>Click here</strong>
              </Link>{" "}
              to add one.
            </span>
          );
        }

        return isEmailSent ? (
          <Tag color="green">Email Sent</Tag>
        ) : isError ? (
          <Tag color="red">Error Sending Email</Tag>
        ) : (
          <Input
            value={localEmailInputs[record.id] || record.payee?.email || ""}
            placeholder="Enter email"
            style={{ width: 300 }}
            onChange={(e) => handleEmailInputChange(record.id, e.target.value)}
            onBlur={() => handleEmailInputBlur(record.id)}
            suffix={
              <Tooltip title="Send Email">
                <Button
                  className="button-yellow"
                  icon={<SendOutlined />}
                  onClick={() =>
                    sendEmail(
                      record,
                      localEmailInputs[record.id] || record.payee?.email
                    )
                  }
                  style={{ padding: "0 8px" }}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </Tooltip>
            }
          />
        );
      },
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  return (
    <Modal
      title="Email Checks"
      open={eCheckModalOpen}
      footer={false}
      style={{ minWidth: "90%", marginTop: -70 }}
      onCancel={() => setECheckModalOpen(false)}
      maskClosable={!Object.values(loadingStates).some((loading) => loading)}
    >
      <Form
        layout="vertical"
        form={form}
        style={{
          backgroundColor: "#ecf0f1",
          padding: 10,
          borderRadius: 10,
          marginBottom: 15,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} lg={4}>
            <Form.Item
              label="Starting Check Number"
              value={startingCheckNumber}
              onChange={(e) => setStartingCheckNumber(e.target.value)}
              name="startingCheckNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the starting check number",
                },
              ]}
            >
              <Input
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
                placeholder="Enter starting check number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={selectedChecksToPrint}
          pagination={false}
        />
      </div>
      <Row style={{ marginTop: 15 }}>
        <Col span={20}></Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => setECheckModalOpen(false)}
            disabled={Object.values(loadingStates).some((loading) => loading)}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendECheckModal;
