import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Popconfirm,
  Tooltip,
  Card,
  notification,
  Badge,
  Tag,
  Input,
  Col,
  Row,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { formatDate } from "../../utils/functions/dates";
import PayeeModal from "./Components/PayeeModal";
import * as XLSX from "xlsx";

const PayeesPage = () => {
  const [payees, setPayees] = useState([]);
  const [filteredPayees, setFilteredPayees] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingPayee, setEditingPayee] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const { payeeId } = useParams();

  const fetchPayees = async () => {
    try {
      const payeesCollection = query(
        collection(db, `clients/${user.clientId}/payees`),
        where("isDeleted", "==", false)
      );
      const payeesSnapshot = await getDocs(payeesCollection);
      const payeesList = payeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPayees(payeesList);
      setFilteredPayees(payeesList);
    } catch (e) {
      notification.error({
        message: "You don't have permission to list payees",
      });
    }
  };

  useEffect(() => {
    fetchPayees();
    if (payeeId) {
      const fetchPayee = async () => {
        try {
          const payeeDoc = await getDoc(
            doc(db, `clients/${user.clientId}/payees`, payeeId)
          );
          if (payeeDoc.exists()) {
            setEditingPayee({ id: payeeDoc.id, ...payeeDoc.data() });
            setIsEditMode(true);
            setIsModalVisible(true);
          } else {
            notification.error({ message: "Payee not found" });
          }
        } catch (e) {
          notification.error({
            message: "Error fetching payee details",
          });
        }
      };
      fetchPayee();
    }
  }, [payeeId]);

  const handleDelete = async (id) => {
    try {
      const now = new Date();
      await updateDoc(doc(db, `clients/${user.clientId}/payees`, id), {
        isDeleted: true,
        dateLastModified: now,
        lastEditedBy: { displayName: user.displayName, uid: user.uid },
      });
      fetchPayees();
    } catch (e) {
      notification.error({
        message: "You don't have permission to edit this record",
      });
    }
  };

  const exportToCsv = () => {
    // Prepare the data to be exported, excluding the 'id' field
    const dataToExport = filteredPayees.map((payee) => {
      // Filter out the 'id' field and convert date fields
      const filteredPayee = Object.fromEntries(
        Object.entries(payee)
          .filter(([key]) => key !== "id") // Exclude 'id'
          .map(([key, value]) => {
            // Check if the value is a Firestore Timestamp and convert it
            if (value?.seconds) {
              const date = new Date(value.seconds * 1000); // Convert seconds to milliseconds
              return [
                key,
                `${
                  date.getMonth() + 1
                }/${date.getDate()}/${date.getFullYear()}`, // Format as MM/DD/YYYY
              ];
            }
            // Convert objects to JSON if necessary, otherwise return the value
            return [
              key,
              typeof value === "object" && value !== null
                ? JSON.stringify(value)
                : value,
            ];
          })
      );
      return filteredPayee;
    });

    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Convert the worksheet to a CSV file
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "payees.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              type="dashed"
              shape="circle"
              onClick={() => {
                navigate(`/payees/${record.id}`);
                setIsModalVisible(true);
              }}
            ></Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this payee? (it will not delete the check records)"
            onConfirm={() => handleDelete(record.id)}
          >
            <Tooltip title="Delete" color="darkRed">
              <Button
                icon={<DeleteOutlined className="error" />}
                type="dashed"
                shape="circle"
              ></Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
      sorter: (a, b) => a.payableTo.localeCompare(b.payableTo),
      filters: [...new Set(payees.map((payee) => payee.payableTo))].map(
        (payableTo) => ({
          text: payableTo,
          value: payableTo,
        })
      ),
      onFilter: (value, record) => record?.payableTo?.includes(value),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      filters: [...new Set(payees.map((payee) => payee.name))].map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record?.name?.includes(value),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
      filters: [...new Set(payees.map((payee) => payee.companyName))].map(
        (companyName) => ({
          text: companyName,
          value: companyName,
        })
      ),
      onFilter: (value, record) => record?.companyName?.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
        { text: "New", value: "new" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        let color;
        switch (status) {
          case "Active":
            color = "bg-success-light success";
            break;
          case "Inactive":
            color = "bg-error-light error";
            break;
          default:
            color = "default";
            break;
        }
        return (
          <Tag className={color}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
      render: (date) => formatDate(date),
    },
    {
      title: "Date Modified",
      dataIndex: "dateModified",
      key: "dateModified",
      sorter: (a, b) => new Date(a.dateModified) - new Date(b.dateModified),
      render: (date) => formatDate(date),
    },
  ];

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = payees.filter(
      (payee) =>
        payee?.name?.toLowerCase().includes(value) ||
        payee?.companyName?.toLowerCase().includes(value) ||
        payee?.status?.toLowerCase().includes(value) ||
        payee?.payableTo?.toLowerCase().includes(value)
    );
    setFilteredPayees(filteredData);
  };

  return (
    <>
      <h1 className="monospace">
        <UserOutlined /> Payees
      </h1>
      <Card style={{ width: "100%"}}>
        <Row gutter={16}>
          <Col xs={24} lg={12} gutter={16}>
            <Button
              className="button-blue"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate("/payees");
                setIsEditMode(false); // Ensure it's not in edit mode
                setEditingPayee(null); // Clear any editing payee
                setIsModalVisible(true); // Show the modal
              }}
            >
              New Payee
            </Button>
          </Col>
          <Col xs={12} lg={12} style={{ textAlign: "right" }}>
            <Input.Search
              placeholder="Search by name, company, or status"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16, width: 300 }}
            />
            <Button onClick={exportToCsv} style={{ marginLeft: "10px" }} className="desktop-only">
              Export to CSV
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={filteredPayees}
                pagination={{ pageSize: 10 }}
              />
            <PayeeModal
              visible={isModalVisible}
              onClose={() => {
                setIsModalVisible(false);
                navigate(-1);
              }}
              isEditMode={isEditMode}
              payee={editingPayee}
              fetchPayees={fetchPayees}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default PayeesPage;
