import { Menu, Badge } from "antd";
import {
  UserOutlined,
  EditOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ImportOutlined,
  RiseOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function MainMenu({ onMenuItemClick }) { // Receive the onMenuItemClick prop
  const { logout, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SHOP_URL;

  const handleMenuClick = (onClick) => {
    onClick();  // Navigate or perform menu action
    if (onMenuItemClick) onMenuItemClick();  // Close drawer if function is provided
  };

  const items = [
    {
      key: "/payees",
      icon: <UserOutlined />,
      label: "Payees",
      onClick: () => handleMenuClick(() => navigate("/payees")),
    },
    {
      key: "/checks",
      icon: <EditOutlined />,
      label: "Checks",
      onClick: () => handleMenuClick(() => navigate("/checks")),
    },
    {
      key: "/fulfillment",
      icon: <SendOutlined />,
      label: "Fulfillment (Soon)",
      onClick: () => handleMenuClick(() => navigate("/fulfillment")),
      disabled: true,
    },
    {
      key: "/import-checks",
      icon: <ImportOutlined />,
      label: (
        <span style={{ position: "relative", display: "inline-block" }}>
          Import Checks
          <Badge
            count="New"
            color="#ff4757"
            style={{
              position: "absolute",
              top: "-15px",
              right: "-30px",
              fontSize: "10px",
              height: "15px",
              lineHeight: "15px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          />
        </span>
      ),
      onClick: () => handleMenuClick(() => navigate("/import-checks")),
    },
    {
      key: "/shop",
      icon: <ShoppingCartOutlined />,
      label: "Buy Supplies",
      onClick: () => handleMenuClick(() => window.open(url, "_blank")),
    },
    {
      key: "/help",
      icon: <QuestionCircleOutlined />,
      label: "Help",
      onClick: () => handleMenuClick(() => navigate("/help")),
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      onClick: () => handleMenuClick(() => navigate("/settings")),
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: () => handleMenuClick(logout),
    },
  ];

  if (user && user?.role === "employee") {
    items.push(
      {
        key: "divider-1",
        type: "divider",
      },
      {
        key: "/clients",
        icon: <UserOutlined />,
        label: "Clients",
        onClick: () => handleMenuClick(() => navigate("/clients")),
      },
      {
        key: "/marketing-hub",
        icon: <RiseOutlined />,
        label: "Marketing Hub",
        onClick: () => handleMenuClick(() => navigate("/marketing-hub")),
      }
    );
  }

  return (
    <Menu
    mode="inline"
    style={{
      backgroundColor: "#dfe6e9",
      height: "100vh",
      paddingTop: "15px",
    }}
    selectedKeys={[location.pathname]}
    defaultSelectedKeys={["/payees"]}
    items={items}
  />
  );
}
