import React from "react";
import {
  Table,
  Button,
  Menu,
  Popconfirm,
  Dropdown,
  Image,
  Tag,
  message,
} from "antd";
import { db, storage } from "../../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { DownOutlined } from "@ant-design/icons";
import { DisplaySignature } from "../../../shared/Components/Signature";
import { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function Accounts({ accounts, clientId, setAccounts }) {
  console.log(accounts);
  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PENDING_VERIFICATION: "pending verification",
    PRE_APPROVED: "pre-approved",
  };

  const deleteAccount = async (accountId) => {
    const accountRef = doc(db, `clients/${clientId}/accounts`, accountId);
    await updateDoc(accountRef, { isDeleted: true });
    // fetchAccounts(); // Refresh the list after deletion
  };

  const approveAccount = async (accountId) => {
    try {
      const accountRef = doc(db, `clients/${clientId}/accounts`, accountId);

      // Update Firestore
      await updateDoc(accountRef, { status: "verified", isDisabled: false });

      // Update local state
      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.id === accountId
            ? { ...account, status: "verified", isDisabled: false }
            : account
        )
      );

      message.success("Account approved successfully.");
    } catch (error) {
      console.error("Error approving account:", error);
      message.error("Failed to approve account.");
    }
  };

  const rejectAccount = async (accountId) => {
    try {
      const accountRef = doc(db, `clients/${clientId}/accounts`, accountId);

      // Update Firestore
      await updateDoc(accountRef, { status: "not approved", isDisabled: true });

      // Update local state
      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.id === accountId
            ? { ...account, status: "not approved", isDisabled: true }
            : account
        )
      );

      message.success("Account rejected successfully.");
    } catch (error) {
      console.error("Error rejecting account:", error);
      message.error("Failed to reject account.");
    }
  };
  // Return status tag
  const returnTag = (account) => {
    if (account.status === STATUS.VERIFIED && !account.isDisabled)
      return <Tag className="bg-success-light success">Verified</Tag>;
    if (account.status === STATUS.PENDING_VERIFICATION)
      return (
        <Tag className="bg-warning-light warning">Pending Verification</Tag>
      );
    if (account.status === STATUS.DISABLED || account.isDisabled)
      return <Tag className="bg-error-light error">Disabled</Tag>;
    if (account.status === STATUS.PRE_APPROVED)
      return <Tag className="bg-success-light success">Pre-Approved</Tag>;
    return <Tag className="bg-gray-light gray">{account.status}</Tag>;
  };

  const columns = [
    {
      title: "Actions",
      key: "actions",
      render: (text, account) => {
        const menu = (
          <Menu>
            <Menu.Item key="1" onClick={() => approveAccount(account.id)}>
              Approve
            </Menu.Item>
            <Menu.Item key="2" onClick={() => rejectAccount(account.id)}>
              Reject
            </Menu.Item>
            <Menu.Item key="3">
              <Popconfirm
                title="Are you sure you want to delete this account?"
                onConfirm={() => deleteAccount(account.id)}
                okText="Yes"
                cancelText="No"
              >
                <span className="error">Delete Account</span>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Verification Type",
      dataIndex: "verificationType",
      key: "verificationType",
    },
    {
      title: "Verification File",
      key: "verificationFile",
      dataIndex: "verificationFile",
      render: (verificationFile, record) => (
        <VerificationFileLink
          clientId={clientId}
          verificationFile={verificationFile}
        />
      ),
    },
    {
      title: "Account Nickname",
      dataIndex: ["account_info", "accountNickname"],
      key: "accountNickname",
    },
    {
      title: "Status",
      key: "status",
      render: (text, account) => returnTag(account),
    },
    {
      title: "Bank",
      dataIndex: ["account_info", "institution_name"],
      key: "institution_name",
    },
    {
      title: "Routing Number",
      dataIndex: ["account_info", "routing_number"],
      key: "routing_number",
    },
    {
      title: "Account Number",
      dataIndex: ["account_info", "mask"],
      key: "account_number",
    },
    {
      title: "Account Owner",
      dataIndex: ["account_info", "ownerName"],
      key: "ownerName",
    },
    {
      title: "Address 1",
      dataIndex: ["account_info", "addressLine1"],
      key: "address1",
    },
    {
      title: "Address 2",
      dataIndex: ["account_info", "addressLine2"],
      key: "address2",
    },
    {
      title: "Signature",
      key: "signature",
      width: 200,
      render: (text, account) =>
        account.account_info.signature ? (
          <DisplaySignature account_info={account.account_info} height={30} />
        ) : (
          "No Signature"
        ),
    },
    {
      title: "Logo",
      key: "logo",
      width: 100,

      render: (text, account) =>
        account.logoBase64 ? (
          <Image
            src={account.logoBase64}
            alt="Logo"
            style={{
              width: 50,
              height: 50,
            }}
          />
        ) : (
          "No Logo"
        ),
    },
  ];
  return (
    <>
      <pre>
        <Table
          dataSource={accounts}
          columns={columns}
          rowKey="id"
          style={{ marginBottom: 20 }}
        />
      </pre>
    </>
  );
}

function VerificationFileLink({ clientId, verificationFile }) {
  const [fileURL, setFileURL] = useState(null);

  useEffect(() => {
    const fetchFileURL = async () => {
      if (verificationFile) {
        try {
          const fileRef = ref(
            storage,
            `verification/${clientId}/${verificationFile}`
          );
          const url = await getDownloadURL(fileRef);
          setFileURL(url);
        } catch (error) {
          console.error("Error fetching file URL:", error);
        }
      }
    };

    fetchFileURL();
  }, [clientId, verificationFile]);

  return fileURL ? (
    <a href={fileURL} target="_blank" rel="noopener noreferrer">
      Open Attachment
    </a>
  ) : (
    "No File Available"
  );
}

export default Accounts;
