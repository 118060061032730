import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Button, Typography } from "antd";

export const SignaturePad = ({ setSignatureImg }) => {
  const sigCanvas = useRef(null);

  // Clears the signature pad
  const clearCanvas = () => {
    sigCanvas.current.clear();
    setSignatureImg(null); // Reset the signature image when cleared
  };

  // Automatically captures the signature after the user finishes drawing
  const handleSignatureEnd = () => {
    const signatureImg = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setSignatureImg(signatureImg);
  };

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <div
          style={{
            width: "100%",
            height: "130px",
            border: "1px solid gray",
            borderRadius: 5,
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            minWidth={0.5}
            maxWidth={1.75}
            canvasProps={{ style: { width: "100%", height: "100%" } }}
            onEnd={handleSignatureEnd} 
          />
        </div>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col span={12}>
          <Button onClick={clearCanvas}>Start Over</Button>
        </Col>
      </Row>
    </>
  );
};

// DisplaySignature component remains the same
export function DisplaySignature({ account_info, height, width }) {
  // console.log('acc info', account_info);
  const signatureStyleClass = account_info.signatureStyle || "signature-style-one";
  if (account_info.signature && account_info.signatureOption === "adopt") {
    return (
      <span className={signatureStyleClass} style={{fontSize:25}}>{account_info.signatureImg} </span>
    );
  } else if (account_info.signature && account_info.signatureOption === "draw") {
    return (
      <img
        style={{ height, width }}
        src={account_info.signatureImg}
        alt="User Signature"
      />
    );
  } else {
    <p>No signature available</p>;
  }
}
