import React from "react";

function Details({ client, clientId }) {
  return (
    <>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ddd",
          borderRadius: "8px",
          fontSize: 12,
          overflow: "hidden",
          marginTop: 10,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
                width: "30%",
              }}
            >
              Client ID
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {clientId}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
                width: "30%",
              }}
            >
              Client Name
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {client.clientName}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Subs
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxWidth: "300px",
              }}
            >
              {client.subscription && client.subscription !== "trial" ?
                Object.entries(client.subscription).map(([key, value]) => (
                  <div key={key} style={{ marginBottom: "4px" }}>
                    <strong>{key}:</strong> {String(value)}
                  </div>
                )): client.subscription}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Date Added
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {new Date(client.dateAdded.seconds * 1000).toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Trial Expires On
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {new Date(
                client.trialExpiresOn.seconds * 1000
              ).toLocaleDateString()}
            </td>
          </tr>
          {/* <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Tutorial Complete
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.tutorialComplete ? "Yes" : "No"}
            </td>
          </tr> */}
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Has Access
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.hasAccess ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                backgroundColor: "#f0f0f0",
                padding: "8px",
              }}
            >
              Date Modified
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {new Date(
                client.dateModified.seconds * 1000
              ).toLocaleDateString()}
            </td>
          </tr>
          {/* <tr>
          <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px" }}>User IDs</td>
          <td style={{ border: "1px solid #ddd", padding: "8px", whiteSpace: "normal", wordWrap: "break-word" }}>{client.userIds.join(", ")}</td>
        </tr> */}
          <tr>
            <td
              style={{
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ddd",
                padding: "8px",
              }}
            >
              Disabled
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.disabled ? "Yes" : "No"}
            </td>
          </tr>
          {/* <tr>
          <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px" }}>Users</td>
          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
            {client.users.map((user, index) => (
              <div key={index} style={{ marginBottom: "8px" }}>
                <p>Role: {user.role}</p>
                <p>Name: {user.name}</p>
                <p>Email: {user.email}</p>
              </div>
            ))}
          </td>
        </tr> */}
          <tr>
            {/* <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px" }}>Preferences Verified</td>
      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
        {client.verifications.preferences ? "Yes" : "No"}
      </td> */}
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ddd",
                padding: "8px",
              }}
            >
              Email Ver
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.verifications.email ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ddd",
                padding: "8px",
              }}
            >
              SMS Ver
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.verifications.sms ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ddd",
                padding: "8px",
              }}
            >
              Bank Ver
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client.verifications.bank ? "Yes" : "No"}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <pre
        style={{
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          overflow: "auto",
          maxHeight: "200px",
        }}
      >
        {JSON.stringify(client)}
      </pre> */}
    </>
  );
}

export default Details;
