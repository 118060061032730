import React from 'react'

function Users({users}) {
  return (
    <>
 <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd", fontSize:12, borderRadius: "8px", overflow: "hidden" }}>
      <tbody>
        {users.map((user, index) => (
          <React.Fragment key={index}>
            <tr>
              <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px", width: "30%", backgroundColor: "#f0f0f0" }}>Email</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.email}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px", backgroundColor: "#f0f0f0" }}>Name</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.name}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "8px", backgroundColor: "#f0f0f0" }}>Role</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.role}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
    </>
  )
}

export default Users