import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export async function lastLogin(clientId, userId) {
  const lastLoginDate = new Date();

  await Promise.all([
    // Only include the client update if clientId is valid
    clientId ? updateDoc(doc(db, `clients/${clientId}`), { lastLoginDate }) : Promise.resolve(),
    updateDoc(doc(db, `users/${userId}`), { lastLoginDate }),
  ]);
}