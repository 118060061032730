import QRCode from "qrcode";

export const generateQRCodeDataURL = async (value) => {
    if (!value) {
      console.error("No input text provided for QR code");
      return Promise.resolve("");
    }
    try {
      return await QRCode.toDataURL(value, { errorCorrectionLevel: "H" });
    } catch (error) {
      console.error("Failed to generate QR code", error);
      return "";
    }
  };